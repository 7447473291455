import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import clsx from 'clsx'
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import makeStyles from '@mui/styles/makeStyles'

import useSecurity from '../hooks/useSecurity'
import { useSecurityAction } from '../../store/ducks/security'
import { useTemplateContext } from '../contexts/TemplateContext'

import useUserClient from '../../clients/UserClient/useUserClient'

import HeaderMenu from './header/HeaderMenu'
import UserOptionMenu from './header/UserOptionMenu'
import ButtonMenu from '../components/Button/ButtonMenu'

import logo from '../../assets/img/logo-white.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 85,
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    '& .MuiGrid-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    border: 0,
    width: theme.sizes.nav,
  },
  relativeMenu: {
    position: 'relative',
  },
  userContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  listContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      backgroundColor: theme.palette.primary.main,
    },
  },
  listIcon: {
    minWidth: 30,
    color: 'initial',
    fontSize: '1.5rem',
    justifyContent: 'center',
  },
  iconSmall: {
    maxWidth: 16,
  },
  icon: {
    maxWidth: 20,
  },
  iconBig: {
    maxWidth: 25,
  },
  iconInicio: {
    maxWidth: 30,
  },
  textActive: {
    color: `${theme.palette.primary.contrastText} !important`,
  },
  listItem: {
    width: 'auto',
    maxHeight: 48,
    color: theme.palette.text.secondary,
  },
  listItemClient: {
    width: 'auto',
    maxHeight: 48,
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  textItem: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& span': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  text: {
    color: theme.palette.text.primary,
    '& span': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  textItemArrow: {
    color: theme.palette.text.secondary,
    '& span': {
      fontSize: '.9rem',
      fontWeight: '500',
    },
  },
  subMenu: {
    '& .MuiListItem-root': {
      padding: '5px 40px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  subMenuArrow: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
  navHorizontal: ({
    [theme.breakpoints.down('md')]: {
      height: 100,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main.contrastText,
      '& img': {
        marginLeft: theme.spacing(2.5),
      },
    },
  }),
  menuButton: {
    padding: theme.spacing(2.5),
    '& .MuiIcon-root': {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hiddenDiv: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      display: 'flex',
    },
  },
}))

const QUOTE = 'quote'
const ENDORSEMENT = 'endorsement'

const QUOTE_URL = `${process.env.REACT_APP_CLIENT}/solicitacao-executivo/cotacao/:`
const ENDORSEMENT_URL = `${process.env.REACT_APP_CLIENT}/solicitacao-executivo/endossos/:`

const CONSULTAR_CLIENTE_URL = '/cliente/consultar-cliente'
const GERIR_CLIENTE_URL = '/cliente/gerir-balanco'
const DEBITOS_URL = '/cliente/debitos'
const GERIR_ACESSO_URL = '/cliente/gerir-acesso'
const CONSULTAR_CLIENTE = [CONSULTAR_CLIENTE_URL]
const GERIR_CLIENTE = [GERIR_CLIENTE_URL]
const DEBITOS = [DEBITOS_URL]
const GERIR_ACESSO = [GERIR_ACESSO_URL]
const NOVO_CHAMADO_URL = '/servicos/novo-chamado'
const NOVO_CHAMADO = [NOVO_CHAMADO_URL]
const SOLICITACOES = ['/solicitacoes']
const SOLICITACOES_URL = '/solicitacoes'
const APOLICES_URL = '/apolices'
const APOLICES = [APOLICES_URL]
const CADASTRO_DE_APOLICES_URL = '/cadastro-de-apolices'
const CADASTRO_DE_APOLICES = [CADASTRO_DE_APOLICES_URL]

const Nav = ({
  logout,
  onDrawerToggle,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    getCredential,
  } = useSecurity()

  const {
    setCredential,
  } = useSecurityAction()

  const userClient = useUserClient()

  const { mobileOpen } = useTemplateContext()

  const [open, setOpen] = useState({})

  const setOpenWhenExpanded = (menu) => {
    setOpen({ ...open, ...menu })
  }

  const getPathName = (paths) => paths.find((item) => {
    const path = location.pathname.split('/').filter((p) => p)
    return path[0]?.includes(item.replace('/', ''))
  })

  const handleSelectItem = (url) => {
    navigate(url)
  }

  const handlesTokenForExecutiveRequests = (typeRequest) => {
    const { t } = getCredential()
    const decode = JSON.parse(atob(t.split('.')[1]))

    if (decode.exp * 1000 < new Date().getTime()) {
      userClient().revalidateTokenUser().then((res) => {
        setCredential({ t: res.token })

        if (typeRequest === QUOTE) {
          window.open(`${QUOTE_URL}${res.token}`)
        }

        if (typeRequest === ENDORSEMENT) {
          window.open(`${ENDORSEMENT_URL}${res.token}`)
        }
      })
    } else {
      if (typeRequest === QUOTE) {
        window.open(`${QUOTE_URL}${t}`)
      }

      if (typeRequest === ENDORSEMENT) {
        window.open(`${ENDORSEMENT_URL}${t}`)
      }
    }
  }

  const items = (
    <List className={classes.listContainer}>
      {mobileOpen && (
        <>
          <ListItemButton
            title="Cliente"
            className={clsx(classes.listItem, 'quotation-tour', (getPathName(CONSULTAR_CLIENTE)
              || getPathName(GERIR_CLIENTE)) && classes.active)}
            onClick={() => setOpenWhenExpanded({ clientMenu: !open.clientMenu })}
          >
            <>
              <ListItemText
                className={clsx(classes.textItem, (getPathName(CONSULTAR_CLIENTE)
                  || getPathName(GERIR_CLIENTE)) && classes.textActive)}
                primary="Cliente"
              />

              {open.clientMenu && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.clientMenu && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          </ListItemButton>
          <Collapse
            className={classes.subMenu}
            in={open.clientMenu}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Consultar Cliente"
                onClick={() => handleSelectItem(CONSULTAR_CLIENTE_URL)}
              >
                <ListItemText
                  className={clsx(classes.textItem, getPathName(CONSULTAR_CLIENTE)
                    && classes.textActive)}
                  primary="Consultar Cliente"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Gerir Balanço"
                onClick={() => handleSelectItem(GERIR_CLIENTE_URL)}
              >
                <ListItemText
                  className={clsx(classes.textItem, getPathName(GERIR_CLIENTE)
                    && classes.textActive)}
                  primary="Gerir Balanço"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Débitos"
                onClick={() => handleSelectItem(DEBITOS_URL)}
              >
                <ListItemText
                  className={clsx(classes.textItem, getPathName(DEBITOS)
                    && classes.textActive)}
                  primary="Débitos"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Gerir Acesso"
                onClick={() => handleSelectItem(GERIR_ACESSO_URL)}
              >
                <ListItemText
                  className={clsx(classes.textItem, getPathName(GERIR_ACESSO)
                    && classes.textActive)}
                  primary="Gerir Acesso"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}

      {!mobileOpen && (
        <ButtonMenu
          title="Cliente"
          className={clsx(classes.textItem, (getPathName(CONSULTAR_CLIENTE)
            || getPathName(GERIR_CLIENTE)) && classes.active)}
        >
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Consultar Cliente"
            component="a"
            onClick={() => handleSelectItem(CONSULTAR_CLIENTE_URL)}
          >
            <ListItemText
              className={clsx(classes.text, getPathName(CONSULTAR_CLIENTE) && classes.textActive)}
              primary="Consultar Cliente"
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Gerir Balanço"
            onClick={() => handleSelectItem(GERIR_CLIENTE_URL)}
          >
            <ListItemText
              className={clsx(classes.text, getPathName(GERIR_CLIENTE) && classes.textActive)}
              primary="Gerir Balanço"
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Débitos"
            onClick={() => handleSelectItem(DEBITOS_URL)}
          >
            <ListItemText
              className={clsx(classes.text, getPathName(DEBITOS) && classes.textActive)}
              primary="Débitos"
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Gerir Acesso"
            onClick={() => handleSelectItem(GERIR_ACESSO_URL)}
          >
            <ListItemText
              className={clsx(classes.text, getPathName(GERIR_ACESSO) && classes.textActive)}
              primary="Gerir Acesso"
            />
          </ListItem>
        </ButtonMenu>
      )}

      {!mobileOpen && (
        <ButtonMenu
          title="Solicitações"
          className={clsx(classes.textItem, (getPathName(SOLICITACOES)) && classes.active)}
        >
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Solicitações"
            onClick={() => handleSelectItem(SOLICITACOES_URL)}
          >
            <ListItemText
              className={classes.text}
              primary="Solicitações"
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem
            onClick={() => handlesTokenForExecutiveRequests(QUOTE)}
            className={clsx(classes.listItemClient)}
            title="Solicitação de Apólice"
          >
            <ListItemText
              className={classes.text}
              primary="Solicitação de Apólice"
            />
          </ListItem>
          <ListItem
            onClick={() => handlesTokenForExecutiveRequests(ENDORSEMENT)}
            className={clsx(classes.listItemClient)}
            title="Solicitação de Endosso"
          >
            <ListItemText
              className={classes.text}
              primary="Solicitação de Endosso"
            />
          </ListItem>
        </ButtonMenu>
      )}

      {mobileOpen && (
        <>
          <ListItemButton
            title="Solicitações"
            className={clsx(classes.listItem, 'policies-tour', getPathName(SOLICITACOES) && classes.active)}
            onClick={() => setOpenWhenExpanded({ requests: !open.requests })}
          >
            <>
              <ListItemText
                className={clsx(classes.textItem, getPathName(SOLICITACOES) && classes.textActive)}
                primary="Solicitações"
              />

              {open.requests && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.requests && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          </ListItemButton>
          <Collapse
            className={classes.subMenu}
            in={open.requests}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Solicitações"
                onClick={() => handleSelectItem(SOLICITACOES_URL)}
              >
                <ListItemText
                  className={classes.textItem}
                  primary="Solicitações"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Solicitação de Apólice"
                onClick={() => handlesTokenForExecutiveRequests(QUOTE)}
              >
                <ListItemText
                  className={classes.textItem}
                  primary="Solicitação de Apólice"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Solicitação de Endosso"
                onClick={() => handlesTokenForExecutiveRequests(ENDORSEMENT)}
              >
                <ListItemText
                  className={classes.textItem}
                  primary="Solicitação de Endosso"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}

      {mobileOpen && (
        <>
          <ListItemButton
            title="Apólices"
            className={clsx(classes.listItem, 'policies-tour', (getPathName(APOLICES)
              || getPathName(CADASTRO_DE_APOLICES)) && classes.active)}
            onClick={() => setOpenWhenExpanded({ policies: !open.policies })}
          >
            <>
              <ListItemText
                className={clsx(classes.textItem, (getPathName(APOLICES)
                  || getPathName(CADASTRO_DE_APOLICES)) && classes.textActive)}
                primary="Apólices"
              />

              {open.policies && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.policies && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          </ListItemButton>
          <Collapse
            className={classes.subMenu}
            in={open.policies}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Apólices"
                onClick={() => handleSelectItem(APOLICES_URL)}
              >
                <ListItemText
                  className={classes.textItem}
                  primary="Apólices"
                />
              </ListItemButton>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Cadastro de Apólices"
                onClick={() => handleSelectItem(CADASTRO_DE_APOLICES_URL)}
              >
                <ListItemText
                  className={classes.textItem}
                  primary="Cadastro de Apólices"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}

      {!mobileOpen && (
        <ButtonMenu
          title="Apólices"
          className={clsx(classes.textItem, (getPathName(APOLICES)
            || getPathName(CADASTRO_DE_APOLICES)) && classes.active)}
        >
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Apólices"
            component="a"
            onClick={() => handleSelectItem(APOLICES_URL)}
          >
            <ListItemText
              className={classes.text}
              primary="Apólices"
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem
            className={clsx(classes.listItemClient)}
            title="Cadastro de Apólices"
            onClick={() => handleSelectItem(CADASTRO_DE_APOLICES_URL)}
          >
            <ListItemText
              className={classes.text}
              primary="Cadastro de Apólices"
            />
          </ListItem>
        </ButtonMenu>
      )}
      {mobileOpen && (
        <>
          <ListItemButton
            title="Serviços"
            className={clsx(classes.listItem, 'quotation-tour', (getPathName(NOVO_CHAMADO))
              && classes.active)}
            onClick={() => setOpenWhenExpanded({ services: !open.services })}
          >
            <>
              <ListItemText
                className={clsx(classes.textItem, (getPathName(NOVO_CHAMADO))
                  && classes.textActive)}
                primary="Serviços"
              />

              {open.services && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.services && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          </ListItemButton>
          <Collapse
            className={classes.subMenu}
            in={open.services}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                className={clsx(classes.listItem)}
                title="Novo Chamado"
                onClick={() => handleSelectItem(NOVO_CHAMADO_URL)}
              >
                <ListItemText
                  className={clsx(classes.textItem, getPathName(NOVO_CHAMADO)
                    && classes.textActive)}
                  primary="Novo Chamado"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}
      {!mobileOpen && (
        <>
          <ButtonMenu
            title="Serviços"
            className={clsx(classes.textItem, (getPathName(NOVO_CHAMADO)) && classes.active)}
          >
            <ListItem
              className={clsx(classes.listItemClient)}
              title="Novo Chamado"
              component="a"
              onClick={() => handleSelectItem(NOVO_CHAMADO_URL)}
            >
              <ListItemText
                className={clsx(classes.text, getPathName(NOVO_CHAMADO) && classes.textActive)}
                primary="Novo Chamado"
              />
            </ListItem>
            <Divider variant="middle" />
          </ButtonMenu>
          <Box width={190} />
        </>
      )}
    </List>
  )

  return (
    <>
      <Hidden mdDown>
        <nav className={classes.root}>
          <Grid container>
            <Grid item sx={{ columnGap: 8 }}>
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>

              <Box>
                {items}
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.headerMenu}>
                <HeaderMenu logout={logout} />
              </Box>
            </Grid>
          </Grid>
        </nav>
      </Hidden>

      <Hidden mdUp implementation="css" className={classes.hiddenDiv}>
        <Grid container alignItems="center">
          <Grid
            md
            item
            xs="auto"
            sm="auto"
            className={clsx(classes.navHorizontal)}
          >
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <IconButton
              color="secondary"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              className={classes.menuButton}
            >
              {
                mobileOpen
                  ? <Icon title="Fechar Menu">close</Icon>
                  : <Icon title="Abrir Menu">menu</Icon>
              }
            </IconButton>
          </Grid>
          <Box width="100%">
            <Drawer
              anchor="top"
              variant="persistent"
              open={mobileOpen}
              onClose={onDrawerToggle}
              classes={{
                paper: clsx(classes.drawerPaper, mobileOpen && classes.relativeMenu),
              }}
            >
              <Box p={1} className={classes.userContainer}>
                <UserOptionMenu logout={logout} />
              </Box>
              {items}
            </Drawer>
          </Box>
        </Grid>
      </Hidden>
    </>
  )
}

Nav.propTypes = {
  logout: PropTypes.func.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
}

export default Nav
