const RegistrationData = (policy) => ({
  codigo: policy?.policyData?.codigo ?? null,
  policyUploadId: policy?.policyData?.policyUploadId ?? null,
  corretora: {
    cnpj: policy?.policyData?.brokerCnpj ?? null,
    nome: policy?.policyData?.brokerName ?? null,
  },
  seguradora: {
    id: policy?.policyData?.insuranceCompanyId ?? null,
    name: policy?.policyData?.insuranceCompanyName ?? null,
  },
  modalidade: {
    codigoModalidade: policy?.policyData?.codeModality ?? null,
    productKey: policy?.policyData?.productKey ?? null,
  },
  numeroProposta: policy?.policyData?.proposalNumber ?? null,
  dataEmissao: policy?.policyData?.dateOfIssue ?? null,
  dataProposta: policy?.policyData?.proposalDate ?? null,
  inicioVigencia: policy?.policyData?.startDateValidity ?? null,
  terminoVigencia: policy?.policyData?.endDateValidity ?? null,
  tipoDocumento: policy?.policyData?.typeDocument ?? null,
  numeroDocumento: policy?.policyData?.policyNumber ?? null,
  numeroPrincipal: policy?.policyData?.mainPolicyNumber ?? null,
  numeroEndossada: policy?.policyData?.endorsedNumber ?? null,
  tipoEndosso: {
    numeroTipoEndosso: policy?.policyData?.endorsementTypeId ?? null,
    descricaoEndosso: policy?.policyData?.endorsementTypeText ?? null,
  },
  segurado: {
    nomeBeneficiario: policy?.insured?.name ?? null,
    cnpjBeneficiario: policy?.insured?.document ?? null,
    logradouroBeneficiario: policy?.insured?.place ?? null,
    numeroBeneficiario: policy?.insured?.number ?? null,
    complementoBeneficiario: policy?.insured?.complement ?? null,
    bairroBeneficiario: policy?.insured?.district ?? null,
    cidadeBeneficiario: policy?.insured?.city ?? null,
    ufBeneficiario: policy?.insured?.state ?? null,
    cepBeneficiario: policy?.insured?.cep ?? null,
    emailBeneficiario: policy?.insured?.email ?? null,
    telefoneBeneficiario: policy?.insured?.phone ?? null,
  },
  tomador: {
    clienteCpfCnpj: policy?.policyHolder?.document ?? null,
    clienteNome: policy?.policyHolder?.name ?? null,
    clienteEndereco: policy?.policyHolder?.place ?? null,
    clienteNumeroEndereco: policy?.policyHolder?.number ?? null,
    clienteComplementoCorrespondente: policy?.policyHolder?.complement ?? null,
    clienteBairroCorrespondente: policy?.policyHolder?.district ?? null,
    clienteCidadeCorrespondente: policy?.policyHolder?.city ?? null,
    clienteUfCorrespondente: policy?.policyHolder?.uf ?? null,
    clienteCepCorrespondente: policy?.policyHolder?.cep ?? null,
    clienteFone: policy?.policyHolder?.phone ?? null,
    clienteEmail: policy?.policyHolder?.email ?? null,
  },
  quantidadeParcelas: policy?.payment?.installmentsNumber ?? null,
  dataVencimento1aParcela: policy?.payment?.dueDate ?? null,
  tipoPagamento: policy?.payment?.paymentType ?? null,
  valorContrato: policy?.payment?.contractValue ?? null,
  premioLiquido: policy?.payment?.liquidPrize ?? null,
  premioTotal: policy?.payment?.totalPrize ?? null,
  policyLmg: policy?.payment?.guaranteeValue ?? null,
})

export default RegistrationData
